import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { ToastProvider } from '@covid/ui/core/Toast'
import { AuthProvider } from './auth'
import theme from '../theme'

function AppProviders({ children }) {
  // }

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <ToastProvider>
        <AuthProvider>
          {/* <UserRiskProvider initialState={initialState} reducer={reducer}> */}
          {children}
          {/* </UserRiskProvider> */}
        </AuthProvider>
      </ToastProvider>
    </ThemeProvider>
  )
}

export default AppProviders
