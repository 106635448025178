import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#065799',
    },
    secondary: {
      main: '#199EA0',
    },
    terciary: {
      main: '#FFFFFF',
    },
    error: {
      main: '#E2322A',
    },
    background: {
      default: '#F7F7F7',
    },
    backgroundSecondary: '#F2F7FB',
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif, "IBM Plex Sans"',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
  overrides: {
    MuiAlert: {
      filledInfo: {
        backgroundColor: '#065799',
      },
    },
  },
})

export default theme
