import React from 'react'

import FullPageSpinner from '@covid/ui/core/loadings/FullPageSpinner'

const NonAuthenticatedApp = React.lazy(() => import('./NonAuthenticatedApp'))

function App() {
  // Pre-load the authenticated side in the background while the user's
  // is in the NonAuthenticatedApp part.

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <NonAuthenticatedApp />
    </React.Suspense>
  )
}

export default App
